<template>
  <div class="refund">
    <div class="refundBanner">
      <div style="background-image: url(https://toufang-images.sybaishan.top/images/refund/newBanner.png); background-position: 0% 0%; background-size: 100% 100%; background-repeat: no-repeat;"></div>
      <img src="https://toufang-images.sybaishan.top/images/refund/newBanner.png" style="width: 100%;height: 100%;" alt="">
    </div>
    <div class="refundModule">
      <div class="refundTitle">商家订单号</div>
      <div class="refundSubTitle">在支付订单页面可找到该单号，具体看下方教程。</div>
      <div class="refundInput">
        <div class="uni-input-wrapper" >
          <div class="uni-input-placeholder input-placeholder" v-if="is_show" >输入商家订单号或订单号</div>
        <input  enterkeyhint="done" v-model="json.order_sn" autocomplete="off" type="text" class="uni-input-input"><!----></div>
      </div>
      <div class="refundButton" @click="onSubmit">立即退款</div>
      <div class="serviceBtns" style="display: none;">
        <div class="btnItem">
          <img  src="https://toufang-images.sybaishan.top/images/refund/telIcon.png" alt="">
          <span >拨打热线</span>
        </div>
        <div class="btnItem">
          <img  src="https://toufang-images.sybaishan.top/images/refund/earphoneIcon.png" alt="">
          <span>联系商家客服</span>
        </div>
      </div>
      <div class="refundRules">
        <p class="refundText">* 提交退款申请后10分钟内款项将原路返回；</p>
        <p class="refundText">* 退款成功后，您购买的权益服务将立即失效，且抽奖活动的奖品视为放弃，感谢理解。</p>
      </div>
    </div>

    <div class="refundGuide">
      <div class="guideTitle">如何找到商家订单号？</div>
      <div class="guideType">
        <div :class="pay_type== 1 ? 'typeItem active' : 'typeItem' " @click="pay_type=1 ; guideImg='https://toufang-images.sybaishan.top/images/refund/aliGuide2.png'">支付宝支付</div>
        <div :class="pay_type == 2 ?'typeItem active':'typeItem'" @click="pay_type=2; guideImg='https://toufang-images.sybaishan.top/images/refund/wechatGuide2.png'">微信支付</div>
      </div>
      <img data-v-93351224="" :src="guideImg" alt="" class="guideImg">
    </div>

  </div>
</template>
<script>
  import {PostRefund} from './services/services'

export default {
		data() {
			return {
				index: 1,
				mobile:'',
        order_sn:'',
        is_show:true,
        pay_type:1,
        guideImg:'https://toufang-images.sybaishan.top/images/refund/aliGuide2.png',
        loading:false,
        json:{
          order_sn:'',
        }
			}
		},
		methods: {
      onSubmit() {
        this.loading = true
        PostRefund(this.json).then(result => {
          this.loading = false
          if (result.data.code == 1) {
            this.$toast.success(result.data.msg)
            this.json={
              phone:'',
              code:''
            }
            this.count_down=0
          } else {
            this.$toast.fail(result.data.msg)
          }
        })
      }
		},
    watch:{
      'json.order_sn':{
        handler(){
          if(this.json.order_sn.length>0){
            this.is_show = false
          }else{
            this.is_show = true
          }
          console.log(this.json.order_sn)
        }
      }
    }
	}
</script>
<style lang="less">
.uni-input-placeholder {
    position: absolute;
    top: auto !important;
    left: 0;
    color: grey;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
}
.uni-input-input {
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}
.uni-input-form, .uni-input-input, .uni-input-placeholder, .uni-input-wrapper {
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: inherit;
}
.uni-input-wrapper{
  display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.refund{
  background-color: #f0f4f6;
  padding-bottom: 31px;
  .refundBanner{
    width: 100%;
    height: 223px;
  }

  .refundModule{
    margin: -104px 15px 0;
    padding: 15px 13px 10px;
    background: #fff;
    border-radius: 15px;
    z-index: 1;
    position: relative;

    .refundTitle{
      color: #000;
      font-size: 15px;
      font-weight: 700;
    }
    .refundSubTitle{
      color: #888;
      font-size: 10px;
    }
    .refundInput{
      margin-top: 5px;
      padding: 20px 13px;
      background: #f0f4f6;
      border: 2px solid #fd8319;
      border-radius: 5px;
    }
    .refundButton{
      margin: 15px auto 0;
      background-image: -webkit-linear-gradient(230deg, #ffa240, #f56126);
      background-image: linear-gradient(220deg, #ffa240, #f56126);
      padding: 11px 46px;
      text-align: center;
      font-size: 20px;
      color: #fff;
      font-weight: 700;
      box-sizing: border-box;
      border-radius: 6px;
    }
    .serviceBtns{
      margin-top: 15px;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      .btnItem{
        text-align: center;
        padding: 10px 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        background: #edf1f3;
        border: 2px solid #d5d5d5;
        border-radius: 26px;

        img{
          height: 16px;
          width: 16px;
        }
      }
    }
    .refundRules{
      margin-top: 13px;
      .refundText{
        font-size: 10px;
        color: #858585;
      }
    }

  }
  .refundGuide{
    margin: 12px 11px 0;
    padding: 11px 9px 23px;
    background: #fff;
    border-radius: 13px;
    .guideTitle{
      color: #000;
      font-size: 14px;
      font-weight: 700;
    }
    .guideImg{
      margin-top: 15px;
      -webkit-transition: opacity .3s ease;
      transition: opacity .3s ease;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent;
      -webkit-user-select: none;
      user-select: none;
      max-width: 100%;
      vertical-align: bottom;
    }
    .guideType{
      margin-top: 15px;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;

      .typeItem{
        padding: 8px 26px;
        background: #fff;
        border: 2px solid silver;
        border-radius: 8px;
        font-size: 8px;
        color: #666;
        font-weight: 700;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        text-align: center;
        white-space: nowrap;
      }
      .active{
        border: none;
        background-image: -webkit-linear-gradient(230deg, #ffa240, #f56126);
        background-image: linear-gradient(220deg, #ffa240, #f56126);
        color: #fff;
      }
    }
  }
}
</style>
